import React from "react"
import PropTypes from "prop-types"
import { Icon } from "utils/helpers/getIcon"
import Categories from "./Categories"
import moment from "moment"
import AvenueImg from "../AvenueImg"
import TitleComponent from "./TitleComponent"

import {
  TeaserContainer,
  ImageWrapper,
  InfoWrapper,
  OuterLink,
  Excerpt,
  Tag,
  Date,
  TeaserButton,
} from "./style.js"

const Teaser = ({
  className,
  children, //custom components that get placed within InfoWrapper
  img, // (gatsby-image fluid)
  title, // (string)
  link, // (string)
  categories, // (array)
  excerpt, // (string)
  tag, // (string)
  date, // (string)
  button, // (object), pass in the same properties as our Button component and a button will be rendered in the InfoWrapper
  testing, // (boolean), set to true to disable the Links making the whole teaser clickable, better for dev inspections
  row, // (boolean), left to right format, image is 50% by default, but this can be changed
  centered, // (boolean), will center the
  outerLink, // (booelean), if this is true, the link inside the title will be disabled, and the OuterLink will be rendered to make the whole teaser clickable
  overlapped, // (boolean), overlaps InfoWrapper over the image
}) => {
  return (
    <TeaserContainer
      className={className}
      row={row}
      testing={testing ? "true" : "false"}
      outerLink={outerLink}
    >
      <ImageWrapper row={row}>
        <AvenueImg fluid={img} />
      </ImageWrapper>

      <InfoWrapper centered={centered} row={row} overlapped={overlapped}>
        {date && <Date> {moment(date).format(" MMM DD, YYYY")}</Date>}
        {title && (
          <TitleComponent
            title={title}
            link={link}
            centered={centered}
            outerLink={outerLink}
          />
        )}
        {tag && <Tag> {tag}</Tag>}

        {categories && <Categories categories={categories} />}
        {excerpt && (
          <Excerpt
            centered={centered}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        )}
        {button && <TeaserButton {...button} />}
        {children}
      </InfoWrapper>

      {outerLink && <OuterLink to={link} />}
    </TeaserContainer>
  )
}

export default Teaser

Teaser.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.array,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  img: PropTypes.object,
}
