import React from "react"
import { FlexRow } from "components/FlexGrid"
import { TeaserCol, StaffGrid } from "./style"
import AvenueImg from "../AvenueImg"
import { StaffTeaser } from "../StaffTeaser/style"

const ThreeTeaserRow = ({ data }) => {
  return (
    <>
      <h3> {data.heading}</h3>
      <StaffGrid gap={[0, 20, 30]} rowGap={[29, 0]}>
        <FlexRow>
          {data.staff.map((item, index) => {
            return (
              <TeaserCol staff key={index} xs={12} sm={4} md={4}>
                <AvenueImg />
                <StaffTeaser
                  link="/"
                  title={item.title}
                  img={item.image}
                  excerpt={item.position}
                ></StaffTeaser>
              </TeaserCol>
            )
          })}
        </FlexRow>
      </StaffGrid>
    </>
  )
}

export default ThreeTeaserRow
