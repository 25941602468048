import React from "react"
import { Title } from "./style"

import AvenueLink from 'components/AvenueLink'

const TitleComponent = ({ link, centered, outerLink, title }) => {
  return (
    <>
      {!link || outerLink ? (
        <Title
          centered={centered}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      ) : (
        <Title centered={centered}>
          <AvenueLink to={link} dangerouslySetInnerHTML={{ __html: title }} />
        </Title>
      )}
    </>
  )
}

export default TitleComponent
