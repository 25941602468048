import styled from "styled-components"
import AvenueLink from "components/AvenueLink"
import { Button } from "../Button"
import { FlexCol, FlexGrid } from "components/FlexGrid"

import { device } from "../../utils/breakpoints.js"

export const TeaserContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.row ? "row" : "column")};
  justify-items: flex-start;
  position: relative;
  padding-bottom: 29px;
  border-bottom: 1px solid #d5d5d5;

  @media ${device.tablet} {
    border: 0;
    padding-bottom: 0;
  }

  a {
    ::before {
      content: ${props =>
        props.testing === "true" || props.outerLink ? "none" : `""`};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  svg {
    height: 36px;
    width: 36px;

    @media ${device.tablet} {
      height: 38px;
      width: 38px;
    }
  }
  :hover {
    svg {
      opacity: 0.8;
    }
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  flex-shrink: 0;
  padding-top: 64.1891892%;
  flex-basis: ${props => (props.row ? "50%" : null)};
  overflow: hidden;
  background: ${props => props.theme.lightGrey};
  margin-bottom: ${props => (props.row ? "0" : "20px")};

  .gatsby-image-wrapper,
  > img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
    display: block;
    transition: 500ms;
    transform: scale(1);

    /* ${TeaserContainer}:hover & {
      transform: scale(1.1);
    } */
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.row ? "flex-start" : props.centered ? "center" : "flex-start"};
  justify-content: ${props => (props.centered ? "center" : "flex-start")};
  height: 100%;
  width: 100%;
  text-align: ${props => (props.centered ? "center" : "left")};
  padding-right: 20px;
  padding-left: ${props => (props.row || props.centered ? "20px" : "0")};

  ${props =>
    props.overlapped &&
    `
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
  `};
`

export const Title = styled.h3`
  margin-bottom: 18px;
  transition: color 100ms;
  width: 100%;
  margin-top: 0;
  color: #052c20;
  font-size: 22px;

  @media ${device.tablet} {
    margin-top: 0;
  }

  ${TeaserContainer}:hover & {
    color: ${props => props.theme.primaryColor};
  }
`

export const CategoriesContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  font-weight: 600;
  grid-gap: 10px;
  margin-bottom: 10px;
`

export const Excerpt = styled.p`
  width: 100%;
  font-size: 18px;
  margin-bottom: 7px;
`

export const Tag = styled.span`
  font-weight: 600;
  margin-bottom: 10px;
`

export const Date = styled(Tag)`
  color: #9b9b9b;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 13px;
`

export const TeaserButton = styled(Button)``

export const OuterLink = styled(AvenueLink)`
  ::before {
    content: "" !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: pointer;
  }
`

export const ThreeTeaserGrid = styled(FlexGrid)`
  padding-top: 29px;
  /* margin-bottom: ${props => props.marginBottom ? '20px': '0'}; */
  border-top: 1px solid ${({ theme }) => theme.lightGrey};

  @media ${device.tablet} {
    border: 0;
    /* padding-bottom: 15px; */
    padding-top: 20px;
    /* margin-bottom: ${props => props.marginBottom ? '70px': '0'}; */

  }

  @media ${device.desktop} {
    padding-top: 29px;
    
  }
`

export const StaffGrid = styled(ThreeTeaserGrid)`
  margin: 0;
  width: 100% !important;
  padding: 0;

  @media ${device.desktop} {
   padding: 0;
  }
`

export const TeaserCol = styled(FlexCol)`
  @media ${device.tablet} {
    position: relative;
    &:after {
      content: ${props => props.staff ? 'none' : ""};
      height: 100%;
      position: absolute;
      width: 1px;
      right: 0;
      background: ${({ theme }) => theme.lightGrey};
    }
  }

  &:nth-child(3n) {
    &:after {
      content: none;
    }
  }
`
