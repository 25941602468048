import React from "react"
import { FlexRow } from "components/FlexGrid"
import Teaser from "./Teaser"
import { TeaserCol, ThreeTeaserGrid } from "./style"
import { ReactComponent as Arrow } from "images/icons/arrow-right-circle.svg"
import AvenueImg from "../AvenueImg"

const ThreeTeaserRow = ({ data }) => {
  return (
    <ThreeTeaserGrid marginBottom gap={[0, 20, 30]} rowGap={[29, 47]}>
      <FlexRow>
        {data.map((item, index) => {
          return (
            <TeaserCol key={index} xs={12} sm={4} md={4}>
              <AvenueImg />
              <Teaser
                link={item.link}
                title={item.title}
                date={item.date || "2020-04-15T13:41:30"}
                excerpt={item.excerpt}
                img={item.featuredImage}
              >
                <Arrow style={{ marginTop: "20px" }} />
              </Teaser>
            </TeaserCol>
          )
        })}
      </FlexRow>
    </ThreeTeaserGrid>
  )
}

export default ThreeTeaserRow
