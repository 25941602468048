import styled from "styled-components"

import { Teaser } from "../Teaser"
import { Title } from "../Teaser/style"
import {device} from "../../utils/breakpoints"


export const StaffTeaser = styled(Teaser)`

  padding-bottom: 12px;

  ${Title} {
    border: 0;
    font-size: 22px;
    padding: 2px 0 0;
    margin: 0 0 5px;
  }

  @media ${device.desktop} {
    padding-bottom: 60px;
  }
`